import { connect } from 'react-redux';
import TitleWithPermissions from './title';
import { collapseAllSegments, expandAllSegments } from '../../../../../../../data/actions';

const mapDispatchToProps = dispatch => ({
  onCollapseAllSegments() {
    dispatch(collapseAllSegments());
  },
  onExpandAllSegments() {
    dispatch(expandAllSegments());
  },
});

const TitleWithPermissionsContainer = connect(
  null,
  mapDispatchToProps,
)(TitleWithPermissions);

export default TitleWithPermissionsContainer;
