import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Clear from '@material-ui/icons/Clear';
import LocationFilter from './locationFilter';
import SortByCreatedAt from './sortByCreatedAt';
import SegmentsFilter from './segmentsFilter';
import CheckpointsAnswerFilter from './checkpointAnswerFilter';
import ShareOfVisibiltyFilter from './shareOfVisibilityFilter';
import PhotoTagsFilter from './photoTagsFilter';
import PhotoCheckpointFilter from './photoCheckpointFilter';
import CyclesFilter from './cyclesFilter';
import DownloadAndZipPhotosButton, {
  downloadEventType,
} from '../dowloadAndZipPhotos/downloadAndZipPhotosButton';
import { preparePhotoFoldersWithPhotosPerSubmission } from '../../services/photoService';
import { Method } from '../../../../../../../../services/fan';

const styles = {
  listScroll: {
    color: '#595959',
    flexGrow: 1,
    overflow: 'auto',
  },
  clearAll: {
    color: '#0071bc',
    margin: 12,
    border: '1px solid #0071bc',
    borderRadius: 10,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '35px',
    height: '35px',
  },
  listNoScroll: { borderBottom: '1px solid #e6e6e6', flexGrow: 0 },
  disableDownloadBtn: { color: '#808080', margin: 12, cursor: 'not-allowed' },
};

export default class Filters extends PureComponent {
  handleFilterPhotoSegmentEvent = status => {
    if (status === downloadEventType.SUCCESS)
      this.handleSegmentTrackEvent('downloadFilteredPhotoGallerySuccess');
    else if (status === downloadEventType.RESTRICTED)
      this.handleSegmentTrackEvent('downloadFilteredPhotoGalleryRestricted');
  };

  handleSegmentTrackEvent = (eventName, params = {}) => {
    const { onTrackEvent } = this.props;

    onTrackEvent(eventName, params);
  };

  render() {
    const {
      locationQuery,
      isMostRecentAtBottom,
      selectedSegmentsByCategories,
      onHandleChange,
      segmentsByCategories,
      checkpoints,
      selectedCheckpointsByCategories,
      sharesOfVisibilityFilters,
      sharesOfVisibilitySelectedValues,
      photosTagsFilters,
      photosTagsSelectedValues,
      photoCheckpointShortDescription,
      photoCheckpointShortDescriptionSelected,
      permissions,
      cyclesFilter,
      cyclesFilterSelected,
      numberOfSubmissions,
      onApplyFilter,
      drawerHeight,
      onClearFilters,
      filteredPhotos,
      campaignId,
    } = this.props;

    return (
      <Grid
        item
        xs={5}
        md={4}
        lg={3}
        style={{
          maxHeight: drawerHeight,
          color: '#595959',
          borderRight: '1px solid #e6e6e6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid item style={styles.listNoScroll}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button testid="clearAll" onClick={onClearFilters} size="small" style={styles.clearAll}>
              <Clear
                style={{
                  color: '0071bc',
                }}
              />
              <p>clear filters</p>
            </Button>

            {filteredPhotos ? (
              <DownloadAndZipPhotosButton
                testid="downloadPhotosByVisitButton"
                photoFolders={preparePhotoFoldersWithPhotosPerSubmission(filteredPhotos)}
                tooltip="Download the photos that match the selected filters."
                campaignId={campaignId}
                onTrackEvent={status => this.handleFilterPhotoSegmentEvent(status)}
                showIcon
                showText
                url={`/campaigns/${campaignId}/photos/zip`}
                method={Method.POST}
              />
            ) : null}
          </div>

          <List disablePadding dense>
            {permissions ? (
              <ListItem>
                <Typography testid="numberOfSubmissions">
                  {' '}
                  -&gt; {numberOfSubmissions} submissions shown
                </Typography>
              </ListItem>
            ) : null}
            <LocationFilter
              testid="LocationFilter"
              locationQuery={locationQuery}
              onHandleChange={onHandleChange}
              onApplyFilter={onApplyFilter}
            />
            <SortByCreatedAt
              testid="SortByCreatedAt"
              isMostRecentAtBottom={isMostRecentAtBottom}
              onHandleChange={onHandleChange}
              onApplyFilter={onApplyFilter}
            />
          </List>
        </Grid>
        <Grid item xs={12} style={styles.listScroll}>
          <List disablePadding dense>
            <SegmentsFilter
              testid="SegmentsFilter"
              selectedSegmentsByCategories={selectedSegmentsByCategories}
              segmentsByCategories={segmentsByCategories}
              onHandleChange={onHandleChange}
            />
            <CheckpointsAnswerFilter
              testid="CheckpointsAnswerFilter"
              checkpoints={checkpoints}
              selectedCheckpointsByCategories={selectedCheckpointsByCategories}
              onHandleChange={onHandleChange}
            />
            <ShareOfVisibiltyFilter
              testid="ShareOfVisibiltyFilter"
              sharesOfVisibilityFilters={sharesOfVisibilityFilters}
              sharesOfVisibilitySelectedValues={sharesOfVisibilitySelectedValues}
              onHandleChange={onHandleChange}
            />
            <PhotoTagsFilter
              testid="PhotoTagsFilter"
              photosTagsFilters={photosTagsFilters}
              photosTagsSelectedValues={photosTagsSelectedValues}
              onHandleChange={onHandleChange}
            />
            <PhotoCheckpointFilter
              testid="PhotoCheckpointFilter"
              photoCheckpointShortDescription={photoCheckpointShortDescription}
              photoCheckpointShortDescriptionSelected={photoCheckpointShortDescriptionSelected}
              onHandleChange={onHandleChange}
              onTrackEvent={() =>
                this.handleSegmentTrackEvent('filterPhotoGallery', {
                  filterName: 'PhotoCheckpointFilter',
                })
              }
            />
            <CyclesFilter
              testid="CyclesFilter"
              cyclesFilter={cyclesFilter}
              cyclesFilterSelected={cyclesFilterSelected}
              onHandleChange={onHandleChange}
            />
          </List>
        </Grid>
      </Grid>
    );
  }
}

Filters.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  numberOfSubmissions: PropTypes.number.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  drawerHeight: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func.isRequired,

  // zipping filter photos on the server
  filteredPhotos: PropTypes.arrayOf(PropTypes.array).isRequired,
  campaignId: PropTypes.string.isRequired,
  // location filter
  locationQuery: PropTypes.string.isRequired,

  // sortByCreatedAt filter
  isMostRecentAtBottom: PropTypes.string.isRequired,

  // segments filter
  segmentsByCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSegmentsByCategories: PropTypes.shape({}).isRequired,

  // checkpoint answer filter
  checkpoints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCheckpointsByCategories: PropTypes.arrayOf(PropTypes.array).isRequired,

  // share of visibility filter
  sharesOfVisibilityFilters: PropTypes.shape({}).isRequired,
  sharesOfVisibilitySelectedValues: PropTypes.shape({}).isRequired,

  // photo tags filter
  photosTagsFilters: PropTypes.shape({}).isRequired,
  photosTagsSelectedValues: PropTypes.shape({}).isRequired,

  // photo checkpoint filter
  photoCheckpointShortDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
  photoCheckpointShortDescriptionSelected: PropTypes.arrayOf(PropTypes.string).isRequired,

  // cycles filter
  permissions: PropTypes.bool.isRequired,
  cyclesFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  cyclesFilterSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTrackEvent: PropTypes.func.isRequired,
};
