import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField } from '@material-ui/core';
import InfoIcon from '../../../../../../../../../assets/images/infoIcon.png';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  logo: {
    height: '18px',
    opacity: '0.7',
    width: '18px',
  },
  tooltip: {
    fontSize: '15px',
  },
  tooltipText: {
    whiteSpace: 'normal',
    display: '-webkit-box',
    lineHeight: '1.6em',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchBox: {
    width: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemText: {
    display: 'inline-block',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 7,
  },
});

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

class SegmentSelect extends React.PureComponent {
  state = {
    searchTerm: '',
    open: false,
  };

  handleChange = event => {
    const { onHandleChangeFilter } = this.props;
    if (this.props.isCheckpointFilter === true) {
      return onHandleChangeFilter(event.target.value, this.props.segmentName[0]);
    }
    return onHandleChangeFilter(event.target.value);
  };

  handleClose = () => {
    this.setState({ open: false, searchTerm: '' });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleSearchTermChange = event => {
    this.setState({ searchTerm: event.target.value });
  };

  renderQuestionInfo = () => (
    <Tooltip
      testid="question"
      disableFocusListener
      disableTouchListener
      title={this.props.checkpointQuestion}
      className={this.props.classes.logo}
      classes={{ tooltip: this.props.classes.tooltip }}
    >
      <img testid="infoIcon" src={InfoIcon} alt="" />
    </Tooltip>
  );

  renderTooltip = string => (
    <Tooltip disableFocusListener disableTouchListener title={string}>
      <div className={this.props.classes.tooltipText}>{string}</div>
    </Tooltip>
  );

  render() {
    const { classes, segmentName, segmentValues, checkpointQuestion, segmentValue } = this.props;
    const { searchTerm } = this.state;

    const filteredSegmentValues = segmentValues[0].filter(value =>
      value.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
      <FormControl className={classes.formControl}>
        <Grid container style={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center">
          <Grid item xs={11} md={11} lg={11}>
            {this.renderTooltip(segmentName[0])}
          </Grid>
          <Grid item xs={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {checkpointQuestion ? this.renderQuestionInfo() : null}
          </Grid>
        </Grid>
        <Select
          multiple
          testid="select"
          value={segmentValue}
          onOpen={this.handleOpen}
          onChange={this.handleChange}
          input={<Input id="segment-label-placeholder" />}
          name="segmentValue"
          renderValue={selected => selected.join(', ')}
          MenuProps={menuProps}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <MenuItem
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
              zIndex: 1,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <TextField
              className={classes.searchBox}
              placeholder={`Search in ${segmentName}`}
              value={searchTerm}
              onChange={this.handleSearchTermChange}
              onClick={event => event.stopPropagation()}
              testId="searchBox"
              fullWidth
            />
          </MenuItem>
          {filteredSegmentValues.length > 0 ? (
            filteredSegmentValues.map(value => (
              <MenuItem testid="menuitem" key={value} value={value}>
                <div className={classes.checkboxContainer}>
                  <Checkbox checked={segmentValue.indexOf(value) > -1} style={{ margin: 0 }} />
                  <ListItemText primary={<span className={classes.listItemText}>{value}</span>} />
                </div>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled testId="noOption">
              No option
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

SegmentSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  segmentValues: PropTypes.arrayOf(PropTypes.array).isRequired,
  segmentName: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChangeFilter: PropTypes.func.isRequired,
  isCheckpointFilter: PropTypes.bool,
  checkpointQuestion: PropTypes.string,
  segmentValue: PropTypes.arrayOf(PropTypes.any),
};

SegmentSelect.defaultProps = {
  isCheckpointFilter: false,
  segmentValue: [],
  checkpointQuestion: null,
};

export default withStyles(styles)(SegmentSelect);
