import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import FilterSelect from '../selects/missionSegmentsSelect';

export default function CyclesFilter({ cyclesFilter, cyclesFilterSelected, onHandleChange }) {
  if (cyclesFilter == null || cyclesFilter.length === 0) {
    return null;
  }

  const onHandleChangeCycle = cyclesFilterSelect => {
    const newCycleFilterSelect = cyclesFilterSelect
      .flatMap(cycle => cyclesFilter.filter(c => c.includes(cycle)))
      .filter(c => c !== null);
    return onHandleChange('cyclesFilterSelected', newCycleFilterSelect);
  };

  const cycleSort = () => {
    const sortedCycles = cyclesFilter.sort((a, b) => {
      if (!b) return 0;
      const matchA = a.match(/Cycle (\d+)/);
      const matchB = b.match(/Cycle (\d+)/);

      const cycleNumberA = parseInt(matchA ? matchA[1] : '0', 10);
      const cycleNumberB = parseInt(matchB ? matchB[1] : '0', 10);

      const dateA = a.slice(-10);
      const dateB = b.slice(-10);

      const formattedDateA = dateA
        .split('-')
        .reverse()
        .join('-');
      const formattedDateB = dateB
        .split('-')
        .reverse()
        .join('-');

      if (cycleNumberA !== cycleNumberB) {
        return cycleNumberA - cycleNumberB;
      }

      return new Date(formattedDateA).getTime() - new Date(formattedDateB).getTime();
    });

    return [sortedCycles];
  };

  return (
    <ListItem>
      <FilterSelect
        testid="cycles"
        segmentValues={cycleSort()}
        segmentName={[`Cycles`]}
        onHandleChangeFilter={onHandleChangeCycle}
        segmentValue={cyclesFilterSelected}
      />
    </ListItem>
  );
}

CyclesFilter.propTypes = {
  cyclesFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  cyclesFilterSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHandleChange: PropTypes.func.isRequired,
};
