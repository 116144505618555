import {
  GET_PHOTOS_BY_CAMPAIGN,
  UPDATE_TAG_PHOTO,
  UPDATE_MISSION_SEGMENTS_EXPAND,
  RESET_SUBMISSIONS_UI,
  EXPAND_SUBMISSION_SEGMENTS,
  COLLAPSE_SUBMISSION_SEGMENTS,
} from './actions';

function photosByCampaign(previousState = {}, action) {
  const { campaignId, type } = action;
  if (type === GET_PHOTOS_BY_CAMPAIGN) {
    const { photos } = action;
    return { ...previousState, [campaignId]: photos };
  }
  if (type === UPDATE_TAG_PHOTO && previousState[campaignId]) {
    const { photo } = action;
    return {
      ...previousState,
      [campaignId]: previousState[campaignId].map(it => {
        if (photo.userMissionId === it.submissionId) {
          return {
            ...it,
            photosPerSubmission: it.photosPerSubmission.map(p => {
              if (p.url === photo.url) {
                return photo;
              }
              return p;
            }),
          };
        }
        return { ...it };
      }),
    };
  }
  return previousState;
}

const initialMissionState = {
  allSegmentsPanelExpanded: false,
};

/**
 * Reducer function to manage the state of missions.
 *
 * @param {Object} state - The current state of missions.
 * @param {Object} action - The action object that may contain a type and payload.
 * @param {string} action.type - The type of action to be handled by the reducer.
 * @param {string} [action.submissionId] - The ID of the submission being updated (optional).
 * @param {boolean} [action.value] - The value to toggle the segment collapse state (optional).
 * @returns {Object} - The updated state of missions.
 */
export const missions = (state = initialMissionState, action) => {
  switch (action.type) {
    case UPDATE_MISSION_SEGMENTS_EXPAND: {
      const { submissionId, value } = action;
      return {
        ...state,
        [submissionId]: { ...state[submissionId], segmentPanelExpanded: value },
      };
    }
    case COLLAPSE_SUBMISSION_SEGMENTS: {
      const newState = Object.keys(state).reduce((acc, key) => {
        acc[key] = { ...state[key], segmentPanelExpanded: false };
        return acc;
      }, {});
      return { ...newState, allSegmentsPanelExpanded: false };
    }
    case EXPAND_SUBMISSION_SEGMENTS: {
      const newState = Object.keys(state).reduce((acc, key) => {
        acc[key] = { ...state[key], segmentPanelExpanded: true };
        return acc;
      }, {});
      return { ...newState, allSegmentsPanelExpanded: true };
    }
    case RESET_SUBMISSIONS_UI: {
      return initialMissionState;
    }
    default: {
      return state;
    }
  }
};

export default photosByCampaign;
