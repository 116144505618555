import React from 'react';
import * as Sentry from '@sentry/browser';
import { WithPermissions } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FileExcel } from 'styled-icons/fa-regular/FileExcel';
import {
  MoreVertOutlined,
  PhotoLibraryOutlined,
  UnfoldLessOutlined,
  UnfoldMoreOutlined,
} from '@material-ui/icons';

import { getExcel, getExcelDashboard } from '../../../../services/excelService';
import DashboardImage from '../../../../../../../assets/images/dashboard.png';
import GoBackButton from './goBackButton';
import isAbsentOrBlank from '../../../../../../../util/stringUtil';
import { downloadEventType } from '../photoGallery/components/dowloadAndZipPhotos/downloadAndZipPhotosButton';

const styles = {
  cardActionStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
    alignItems: 'center',
  },
  iconDownloadCampaign: {
    top: '3px',
  },
  downloadCampaign: {
    color: '#03a6e9',
  },
  logo: {
    maxHeight: '25px',
    color: '#0071bc',
  },
  error: {
    textAlign: 'center',
    border: '1px solid red',
    padding: '10px',
    color: 'red',
  },
  dialog: {
    zIndex: 5,
  },
  title: {
    textAlign: 'left',
    fontWeight: 900,
  },
  button: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#0071bc',
    borderRadius: 10,
    color: 'white',
    textDecoration: 'none',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 20,
    justifyContent: 'center',
  },
};

const tooltip = {
  fullscreen: 'Open photo gallery',
  excelDownload: 'Download raw data (.xlsx)',
  downloadCampaign: 'Download photo gallery (.zip)',
  dashboardExport: 'Download charts (.xlsx)',
  moreActions: 'More actions',
};

export class Title extends React.PureComponent {
  state = {
    isExcelLoading: false,
    excelLoadingError: undefined,
    isInitialLoad: true,
    isDialogBoxOpen: false,
    showMoreActionsMenu: false,
    anchorEl: null,
  };

  componentDidUpdate() {
    if (this.state.isInitialLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isInitialLoad: false,
      });
      const { inAppName, id, name } = this.props.record;
      const { onTrackEvent } = this.props;
      onTrackEvent('viewCampaign', {
        inAppName,
        campaignId: id,
        campaignName: name,
      });
    }
  }

  handleModalButtonClick = () => {
    this.setState({ isDialogBoxOpen: !this.state.isDialogBoxOpen });
  };

  handleDownloadFullCampaignSegmentEvent = status => {
    const { inAppName, id, name } = this.props.record;
    const { onTrackEvent } = this.props;

    let eventName;

    if (status === downloadEventType.SUCCESS) eventName = 'downloadFullPhotoGallerySuccess';
    else if (status === downloadEventType.RESTRICTED)
      eventName = 'downloadFullPhotoGalleryRestricted';

    onTrackEvent(eventName, {
      inAppName,
      campaignName: name,
      campaignId: id,
    });
  };

  handleActionMenuClose = () => {
    this.setState({ showMoreActionsMenu: false, anchorEl: null });
  };

  extractErrorClassName = fullyQualifiedName => {
    const nullSafeInput = fullyQualifiedName || '';
    const lastIndexOfDot = nullSafeInput.lastIndexOf('.');
    return lastIndexOfDot < 0
      ? nullSafeInput
      : nullSafeInput.substring(lastIndexOfDot + 1, nullSafeInput.length);
  };

  downloadExcel = () => {
    this.setState({ isExcelLoading: true, excelLoadingError: undefined }, async () => {
      try {
        const result = await getExcel(this.props.record.id);

        const { inAppName, id, name } = this.props.record;
        const { onTrackEvent } = this.props;

        const errorType = this.extractErrorClassName((result || {}).type || 'UnknownError');

        if (errorType === 'CampaignExcelExportNotAllowed') {
          this.setState({ isDialogBoxOpen: true, isExcelLoading: false });
          onTrackEvent('downloadExcelRestricted', {
            inAppName,
            campaignId: id,
            campaignName: name,
          });
          return;
        }

        this.setState({
          excelLoadingError: result
            ? {
                type: errorType,
                message:
                  (result || {}).message ||
                  'Excel file download error, please try again later or contact us if it persists.',
              }
            : undefined,
          isExcelLoading: false,
        });

        if (errorType === 'CampaignExportNotYetAvailable' || result === null) {
          onTrackEvent(
            errorType === 'CampaignExportNotYetAvailable'
              ? 'downloadExcelComeBack'
              : 'downloadExcelSuccess',
            {
              inAppName,
              campaignId: id,
              campaignName: name,
            },
          );
        }
      } catch (e) {
        Sentry.captureException(e);
        this.setState({
          excelLoadingError: {
            type: 'UnhandledError',
            message: `Unhandled error when downloading file. Detail: ${e}`,
          },
          isExcelLoading: false,
        });
      }
    });
  };

  /* This is a dashboardChart download */
  downloadDashboard = () => {
    this.setState({ isExcelLoading: true }, async () => {
      try {
        await getExcelDashboard(this.props.record.id);
        this.setState({
          isExcelLoading: false,
        });
      } catch (e) {
        Sentry.captureException(e);
        this.setState({
          excelLoadingError: {
            type: 'UnhandledError',
            message: `Unhandled error when downloading file. Detail: ${e}`,
          },
          isExcelLoading: false,
        });
      }
    });
  };

  renderPhotogalleryDrawers = () => {
    const { id, inAppName, name } = this.props.record;
    const { onTrackEvent } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={tooltip.fullscreen}>
          <IconButton
            testid="fullscreenButton"
            onClick={() => {
              this.props.onToggleViewGallery('calc(100vh - 140px)');
              onTrackEvent('openPhotoGalleryFull', {
                inAppName,
                campaignId: id,
                campaignName: name,
              });
            }}
          >
            <PhotoLibraryOutlined testid="fullscreen" style={styles.logo} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  renderPhotoGalleryIcons() {
    const { record } = this.props;
    if (!isAbsentOrBlank(record.metabaseId) || !isAbsentOrBlank(record.metabaseDashboardUrl)) {
      return this.renderPhotogalleryDrawers();
    }
    return null;
  }

  renderExportDashboard = () => {
    const { record } = this.props;
    if (
      (!isAbsentOrBlank(record.metabaseId) && isAbsentOrBlank(record.metabaseDashboardUrl)) ||
      (!isAbsentOrBlank(record.metabaseDashboardUrl) &&
        record.metabaseDashboardUrl.includes('.snooper-app.com'))
    ) {
      return (
        <React.Fragment>
          <Tooltip title={tooltip.dashboardExport}>
            <IconButton testid="exportDashboard" onClick={this.downloadDashboard}>
              <img src={DashboardImage} alt="" style={styles.logo} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      );
    }
    return null;
  };

  renderExcelDownloadProhibitModal = () => (
    <Dialog
      open={this.state.isDialogBoxOpen}
      style={styles.dialog}
      testid="dialog"
      onClose={() => this.handleModalButtonClick()}
    >
      <DialogTitle style={styles.title} testid="dialogTitle">
        {`The Excel download is disabled for this campaign.`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText testid="dialogContent">
          {`To request access, please contact your Wiser customer success representative.`}
        </DialogContentText>
        <DialogActions testid="dialogActions">
          <div style={styles.buttonContainer}>
            <Button onClick={() => this.handleModalButtonClick()} style={styles.button}>
              Close
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  renderMoreActionMenu = () => {
    const { onCollapseAllSegments, onExpandAllSegments } = this.props;
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        open={this.state.showMoreActionsMenu}
        onClose={() => {
          this.handleActionMenuClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          marginTop: 45,
        }}
      >
        <MenuItem
          testId="collapseAllSegments"
          onClick={() => {
            onCollapseAllSegments();
            this.handleActionMenuClose();
          }}
        >
          <IconButton>
            {
              <UnfoldLessOutlined
                style={{
                  color: '#0071bc',
                }}
              />
            }
          </IconButton>
          <span style={{ marginLeft: '10px' }}>Collapse all segments</span>
        </MenuItem>
        <MenuItem
          testId="expandAllSegments"
          onClick={() => {
            onExpandAllSegments();
            this.handleActionMenuClose();
          }}
        >
          <IconButton>
            {
              <UnfoldMoreOutlined
                style={{
                  color: '#0071bc',
                }}
              />
            }
          </IconButton>
          <span style={{ marginLeft: '10px' }}>Expand all segments</span>
        </MenuItem>
      </Menu>
    );
  };

  renderMoreActionMenuButton = () => {
    const { record, openGallery } = this.props;

    // eslint-disable-next-line react/jsx-indent
    return openGallery ||
      (isAbsentOrBlank(record.metabaseId) && isAbsentOrBlank(record.metabaseDashboardUrl)) ? (
      // eslint-disable-next-line react/jsx-indent
      <Tooltip title={tooltip.moreActions}>
        <IconButton
          onClick={event => {
            this.setState({
              showMoreActionsMenu: true,
            });
            this.setState({
              anchorEl: event.currentTarget,
            });
          }}
          testId="moreActionsButton"
        >
          <MoreVertOutlined style={{ color: '#0071bc' }} />
        </IconButton>
      </Tooltip>
    ) : null;
  };

  render() {
    const { record, permissions } = this.props;
    const { isExcelLoading, excelLoadingError } = this.state;

    const hasExcelLoadingError = !!excelLoadingError;
    if (isExcelLoading) {
      return (
        <div style={styles.progress}>
          <CircularProgress testid="excelProgress" />
          <Typography>Excel file downloading</Typography>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.renderExcelDownloadProhibitModal()}
        <div style={styles.cardActionStyle}>
          <div>
            <GoBackButton />
            <span testid="title"> {record.name ? `${record.name}` : ''}</span>
          </div>

          <div testid="galleryIcons">
            <Tooltip title={tooltip.excelDownload}>
              <IconButton testid="excelIconButton" onClick={() => this.downloadExcel()}>
                <FileExcel size={20} color="#0071bc" />
              </IconButton>
            </Tooltip>
            {permissions ? this.renderExportDashboard() : null}
            {this.renderPhotoGalleryIcons()}
            {this.renderMoreActionMenuButton()}
            {this.state.showMoreActionsMenu && this.renderMoreActionMenu()}
          </div>
        </div>
        {hasExcelLoadingError ? (
          <div style={styles.error}>
            <Typography testid="errorLoadingExcel">
              {excelLoadingError.type}
              <br />
              {excelLoadingError.message}
            </Typography>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

Title.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  onToggleViewGallery: PropTypes.func.isRequired,
  permissions: PropTypes.bool,
  onTrackEvent: PropTypes.func.isRequired,
  openGallery: PropTypes.bool,
  onCollapseAllSegments: PropTypes.func.isRequired.isRequired,
  onExpandAllSegments: PropTypes.func.isRequired,
};

Title.defaultProps = {
  record: null,
  permissions: false,
  openGallery: false,
};

const TitleWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <Title {...props} permissions={permissions} />} />
);

export default TitleWithPermissions;
