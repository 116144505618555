import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import onUpdatePhotosService from '../../services/updatePhoto.service';

import UserCheckpointAnswerPhotoCardWithPermissionsContainer from '../cards/userCheckpointAnswerPhotoCard.container';

const styles = {
  noMatch: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
};

export class PhotosPerSubmissionsPresenter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 300,
    });
    this.listRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { missions } = this.props;
    this.handleSegmentPanelExpansion();
    if (
      prevProps.missions &&
      prevProps.missions.allSegmentsPanelExpanded !== missions.allSegmentsPanelExpanded
    ) {
      this.forceUpdate();
    }
  }

  onUpdatePhotos = (dataFromChild, submissionId) => {
    const { onPhotosByCampaign, id, photosByCampaign } = this.props;
    const newPhotos = onUpdatePhotosService(photosByCampaign[id], dataFromChild, submissionId);
    return onPhotosByCampaign(id, newPhotos);
  };

  handleSegmentPanelExpansion = index => {
    if (index !== undefined) {
      this.cache.clear(index);
    } else {
      this.cache.clearAll();
    }

    if (this.listRef.current) {
      this.listRef.current.recomputeRowHeights(index);
      this.listRef.current.forceUpdateGrid();
    }
  };

  render() {
    const { drawerHeight, filteredPhotos, isFiltering, record, onHandleErrorMessage } = this.props;
    if (!filteredPhotos || isFiltering) {
      return (
        <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, ...styles.noMatch }}>
          <CircularProgress testid="loader" />
        </Grid>
      );
    }
    if (filteredPhotos.length === 0 && !isFiltering) {
      return (
        <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, ...styles.noMatch }}>
          <p testid="noMatch"> No submissions match this filter </p>
        </Grid>
      );
    }
    const photos = filteredPhotos.filter(photo => photo.photosPerSubmission);

    const { onTrackEvent } = this.props;

    return (
      <Grid item xs={7} md={8} lg={9} style={{ maxHeight: drawerHeight, overflowY: 'hidden' }}>
        <AutoSizer testid="photoCards">
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={this.cache}
              rowCount={photos.length}
              overscanRowCount={3}
              rowHeight={this.cache.rowHeight}
              rowRenderer={({ index, style, key, parent }) => {
                const checkPointAnswerPhotoByMission = photos[index];

                return (
                  <CellMeasurer
                    key={key}
                    cache={this.cache}
                    columnIndex={0}
                    rowIndex={index}
                    parent={parent}
                  >
                    <div style={{ ...style, padding: 10 }} key={key}>
                      <UserCheckpointAnswerPhotoCardWithPermissionsContainer
                        location={checkPointAnswerPhotoByMission.location}
                        locationDetails={checkPointAnswerPhotoByMission.locationDetails}
                        checkpointAnswerPhotosByMission={
                          checkPointAnswerPhotoByMission.photosPerSubmission
                        }
                        missionId={checkPointAnswerPhotoByMission.missionId}
                        segments={checkPointAnswerPhotoByMission.segments}
                        submissionId={checkPointAnswerPhotoByMission.submissionId}
                        record={record}
                        onUpdatePhotos={this.onUpdatePhotos}
                        cycle={checkPointAnswerPhotoByMission.cycle}
                        onHandleErrorMessage={onHandleErrorMessage}
                        width={width}
                        onTrackEvent={onTrackEvent}
                        onSegmentPanelExpansion={() => {
                          this.handleSegmentPanelExpansion(index);
                        }}
                      />
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </Grid>
    );
  }
}

PhotosPerSubmissionsPresenter.propTypes = {
  id: PropTypes.string.isRequired,
  drawerHeight: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onPhotosByCampaign: PropTypes.func.isRequired,
  photosByCampaign: PropTypes.shape({}).isRequired,
  filteredPhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  onTrackEvent: PropTypes.func.isRequired,
  missions: PropTypes.shape({
    allSegmentsPanelExpanded: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PhotosPerSubmissionsPresenter;
