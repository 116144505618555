import { connect } from 'react-redux';
import getMissions from '../../../../../../../../../data/selector';
// eslint-disable-next-line import/no-named-as-default
import PhotosPerSubmissionsPresenter from './photosPerSubmissions';

const mapStateToProps = state => ({
  missions: getMissions(state),
});

const PhotosPerSubmissionsContainer = connect(
  mapStateToProps,
  null,
)(PhotosPerSubmissionsPresenter);

export default PhotosPerSubmissionsContainer;
