import { connect } from 'react-redux';

import UserCheckpointAnswerPhotoCardWithPermissions from './userCheckpointAnswerPhotoCard';
import { updateMissionSegmentCollapseToggle } from '../../../../../../../../../data/actions';
import getMissions, { getMissionSegmentCollapsed } from '../../../../../../../../../data/selector';

const mapStateToProps = (state, ownProps) => ({
  missions: getMissions(state),
  segmentPanelExpanded: getMissionSegmentCollapsed(state, ownProps.submissionId),
});

const mapDispatchToProps = dispatch => ({
  onUpdateMissionSegmentCollapseToggle(...args) {
    dispatch(updateMissionSegmentCollapseToggle(...args));
  },
});

const UserCheckpointAnswerPhotoCardWithPermissionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCheckpointAnswerPhotoCardWithPermissions);

export default UserCheckpointAnswerPhotoCardWithPermissionsContainer;
