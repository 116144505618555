import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { resetSubmissionUIs } from '../../../../../../../data/actions';

const styles = {
  arrowColor: { color: '#03a6e9' },
};

const mapDispatchToProps = dispatch => ({
  onRemoveAllMissions() {
    dispatch(resetSubmissionUIs());
  },
  goBack: () => dispatch(goBack()),
});

const GoBack = connect(
  null,
  mapDispatchToProps,
)(({ record, data, ...props }) => (
  <IconButton
    onClick={() => {
      props.goBack();
      props.onRemoveAllMissions();
    }}
  >
    <ArrowBackIosOutlined testid="back" style={styles.arrowColor} color="#0071bc" />
  </IconButton>
));

export default GoBack;
