export const GET_PHOTOS_BY_CAMPAIGN = 'GET_PHOTOS_BY_CAMPAIGN';
export const UPDATE_TAG_PHOTO = 'UPDATE_TAG_PHOTO';
export const UPDATE_MISSION_SEGMENTS_EXPAND = 'UPDATE_MISSION_SEGMENTS_EXPAND';
export const RESET_SUBMISSIONS_UI = 'RESET_SUBMISSIONS_UI';
export const COLLAPSE_SUBMISSION_SEGMENTS = 'COLLAPSE_SUBMISSION_SEGMENTS';
export const EXPAND_SUBMISSION_SEGMENTS = 'EXPAND_SUBMISSION_SEGMENTS';

export const updatePhotosByCampaign = (campaignId, photos) => ({
  type: GET_PHOTOS_BY_CAMPAIGN,
  photos,
  campaignId,
});

export const updatePhoto = (campaignId, photo) => ({
  type: UPDATE_TAG_PHOTO,
  campaignId,
  photo,
});

/**
 * Action to update the collapse state of a mission segment.
 *
 * @param {string} submissionId - The ID of the mission to update.
 * @param {boolean} value - The new collapse state of the mission segment.
 * @returns {Object} The action object to update the mission segment collapse state.
 */
export const updateMissionSegmentCollapseToggle = (submissionId, value) => ({
  type: UPDATE_MISSION_SEGMENTS_EXPAND,
  submissionId,
  value,
});

export const collapseAllSegments = () => ({
  type: COLLAPSE_SUBMISSION_SEGMENTS,
});

export const expandAllSegments = () => ({
  type: EXPAND_SUBMISSION_SEGMENTS,
});
/**
 * Action to remove all submissions from the redux state.
 *
 * @returns {Object} The action object to remove all missions.
 */
export const resetSubmissionUIs = () => ({
  type: RESET_SUBMISSIONS_UI,
});
