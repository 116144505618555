import React from 'react';
import { showNotification, WithPermissions } from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lightbox from 'react-image-lightbox-rotate-fixed';
import 'react-image-lightbox/style.css';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { UnfoldLessOutlined, UnfoldMoreOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import RotateRight from '@material-ui/icons/RotateRight';
import RotateLeft from '@material-ui/icons/RotateLeft';
import config from '../../../../../../../../../setup/config';
import { rotatePhoto, appendTimestamp } from './services/deletePhoto.service';
import ReviewersLink from './components/reviewersLink/reviewersSubmissionLink';
import PhotosPerSubmission from './components/photosPerSubmission/photosPerSubmission';
import DownloadAndZipPhotosButton, {
  downloadEventType,
} from '../dowloadAndZipPhotos/downloadAndZipPhotosButton';
import { preparePhotoFoldersWithoutPhotosPerSubmission } from '../../services/photoService';
import { Method } from '../../../../../../../../services/fan';
import './styles/userCheckpointAnswerPhoto.css';

const styles = {
  root: {
    marginLeft: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    borderRadius: '5px',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '100%',
  },
  iconButton: {
    top: '3px',
  },
  downloadPhoto: {
    color: '#9b9d9f',
  },
  cardHeader: {
    fontSize: '1rem',
    padding: '0px',
    margin: '0px',
  },
  lightbox: {
    zIndex: 10,
    position: 'absolute',
  },
};

function imagesLoaded(parentNode) {
  const imgElements = [...parentNode.querySelectorAll('img')];
  for (let i = 0; i < imgElements.length; i += 1) {
    const img = imgElements[i];
    if (!img.complete) {
      return false;
    }
  }

  return true;
}

class UserCheckpointAnswerPhotoCard extends React.PureComponent {
  state = {
    photoIndex: 0,
    isOpen: false,
    isLoading: true,
    isRotating: false,
    showSegmentToggle: true,
    titleOverflowing: true,
  };

  componentDidMount() {
    this.checkPhotoTitleOverflow();
    this.checkSegmentTextOverflow();
  }

  componentDidUpdate(prevProps) {
    const { permissions, segmentPanelExpanded } = this.props;
    if (this.state.isOpen && permissions) this.onSetupListener(100);

    if (prevProps.segmentPanelExpanded !== segmentPanelExpanded) this.checkSegmentTextOverflow();
  }

  onSetupListener = timeout => {
    setTimeout(() => {
      const rotateRight = document.querySelectorAll('button.ril-rotate-right');
      const rotateLeft = document.querySelectorAll('button.ril-rotate-left');
      if (rotateRight.length > 0) {
        rotateRight[0].remove();
      }
      if (rotateLeft.length > 0) {
        rotateLeft[0].remove();
      }
    }, timeout);
  };

  onForceReloadPhoto = url => {
    const { checkpointAnswerPhotosByMission, onUpdatePhotos, submissionId } = this.props;
    const newPhotos = checkpointAnswerPhotosByMission.map(photo => {
      if (photo.url === url) {
        return {
          ...photo,
          url: appendTimestamp(url),
          thumbnailUrl: photo.thumbnailUrl ? appendTimestamp(photo.thumbnailUrl) : undefined,
        };
      }
      return photo;
    });
    onUpdatePhotos(newPhotos, submissionId);
  };

  onRotatePhoto = degree => async () => {
    const { checkpointAnswerPhotosByMission } = this.props;
    const { url, key } = checkpointAnswerPhotosByMission[this.state.photoIndex];

    this.setState({ isRotating: true });
    try {
      await rotatePhoto(this.props.record.id, degree, key);
      this.onForceReloadPhoto(url);
      this.setState({ isRotating: false });
    } catch {
      this.setState({ isRotating: false });
      showNotification('Could not rotate the photo as expected', 'warning');
    }
  };

  onDeletePhoto = dataFromChild => {
    const { onUpdatePhotos, submissionId } = this.props;
    onUpdatePhotos(dataFromChild, submissionId);
    this.setState({
      photoIndex: 0,
    });
  };

  onCloseRequest = () => {
    this.setState({ isOpen: false });
  };

  getNextPhoto = (...args) => this.getPhoto(1)(...args);

  getPrevPhoto = (...args) => this.getPhoto(-1)(...args);

  getPhoto = step => (checkpointAnswerPhotosByMission, photoIndex) => {
    const photosNumber = checkpointAnswerPhotosByMission.length;
    const newPhotoIndex = (photoIndex + step + photosNumber) % photosNumber;
    return checkpointAnswerPhotosByMission[newPhotoIndex].url;
  };

  getLinkForDownloadPhoto = (record, currentPhoto) => {
    const url = config.FAN_BACKEND_BASE_URL;
    const key = encodeURIComponent(currentPhoto.url.split('snooper-photos/')[1]);
    const shortDescr = currentPhoto.shortDescription;

    return `${url}/campaigns/${
      record.id
    }/photos/photo?key=${key}&short_description=${shortDescr}&parse-session=${localStorage.getItem(
      'token',
    )}`;
  };

  setSrcImage = prevSrc => {
    const elementByClassName = document.getElementsByClassName(prevSrc);
    if (elementByClassName.length > 0) {
      elementByClassName[0].src = '';
    }
  };

  showAllSegments = () => {
    const {
      onUpdateMissionSegmentCollapseToggle,
      submissionId,
      onSegmentPanelExpansion,
    } = this.props;
    onUpdateMissionSegmentCollapseToggle(submissionId, true);
    onSegmentPanelExpansion();
  };

  hideAllSegment = () => {
    const {
      onUpdateMissionSegmentCollapseToggle,
      submissionId,
      onSegmentPanelExpansion,
    } = this.props;
    onUpdateMissionSegmentCollapseToggle(submissionId, false);
    onSegmentPanelExpansion();
  };

  segmentTextRef = React.createRef();
  photoTitleRef = React.createRef();

  checkSegmentTextOverflow = () => {
    const segmentTextRef = this.segmentTextRef.current;

    if (segmentTextRef) {
      if (segmentTextRef.scrollWidth > 0 || segmentTextRef.scrollHeight > 0) {
        const isSegmentTextOverflowing = segmentTextRef.scrollWidth > segmentTextRef.clientWidth;
        this.setState({
          showSegmentToggle: isSegmentTextOverflowing,
        });
      }
    }
  };

  checkPhotoTitleOverflow = () => {
    const photoTitleRef = this.photoTitleRef.current;

    if (photoTitleRef) {
      if (photoTitleRef.scrollWidth > 0 || photoTitleRef.scrollHeight > 0) {
        const isTitleTextOverflowing = photoTitleRef.scrollWidth > photoTitleRef.clientWidth;
        this.setState({
          titleOverflowing: isTitleTextOverflowing,
        });
      }
    }
  };

  openLightbox = index => () => {
    this.setState({
      photoIndex: index,
      isOpen: true,
    });
  };

  handleImageChange = () => {
    this.setState({
      isLoading: !imagesLoaded(this.galleryElement),
    });
  };

  handleTrackSegmentEvent = status => {
    const { missionId, submissionId, location, onTrackEvent } = this.props;
    let eventName;

    if (status === downloadEventType.SUCCESS) {
      eventName = 'downloadSubmissionPhotoGallerySuccess';
    } else if (status === downloadEventType.RESTRICTED) {
      eventName = 'downloadSubmissionPhotoGalleryRestricted';
    }

    onTrackEvent(eventName, {
      missionId,
      submissionId,
      location,
    });
  };

  moveNext = () => {
    this.setSrcImage('ril-image-prev ril__imagePrev ril__image');
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.props.checkpointAnswerPhotosByMission.length,
    });
  };

  movePrev = () => {
    this.setSrcImage('ril-image-next ril__imageNext ril__image');
    this.setState({
      photoIndex:
        (this.state.photoIndex + this.props.checkpointAnswerPhotosByMission.length - 1) %
        this.props.checkpointAnswerPhotosByMission.length,
    });
  };

  formatSegmentDescriptions = (segments, cycle) => {
    const segmentValues = segments.map(value => value.value).join(' - ');

    let additionalText = '';
    if (cycle) {
      additionalText = ` - ${cycle}`;
    }

    const fullText = segmentValues + additionalText;

    return {
      segmentText: fullText,
    };
  };

  formatLocationTooltip = location => {
    if (typeof location === 'object') {
      const { name, address, state, suburb, country, postCode } = location;
      return `
      Name: ${name || ''}
      Address: ${address || ''}
      State: ${state || ''}
      Suburb: ${suburb || ''}
      PostCode: ${postCode || ''}
      Country: ${country || ''}
    `.trim();
    }
    return location;
  };

  renderLightBox() {
    const { classes, record, checkpointAnswerPhotosByMission, permissions } = this.props;
    const { photoIndex, isRotating } = this.state;
    const currentPhoto = checkpointAnswerPhotosByMission[photoIndex];

    const nextSrc = this.getNextPhoto(checkpointAnswerPhotosByMission, photoIndex);
    const prevSrc = this.getPrevPhoto(checkpointAnswerPhotosByMission, photoIndex);
    return (
      <Lightbox
        testid="lightBox"
        mainSrc={checkpointAnswerPhotosByMission[photoIndex].url}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        onCloseRequest={this.onCloseRequest}
        onPreMovePrevRequest={this.movePrev}
        onPreMoveNextRequest={this.moveNext}
        toolbarButtons={[
          <IconButton className={classes.iconButton}>
            <a
              href={this.getLinkForDownloadPhoto(record, currentPhoto)}
              download={`${checkpointAnswerPhotosByMission[photoIndex].shortDescription}`.jpg}
            >
              <ArrowDownward className={classes.downloadPhoto} />
            </a>
          </IconButton>,
          null,
          permissions ? (
            <IconButton testid="iconButtonLeft" disabled={isRotating}>
              <RotateLeft
                testid="RotateLeft"
                className={classes.downloadPhoto}
                onClick={this.onRotatePhoto(-90.0)}
              />
            </IconButton>
          ) : null,
          permissions ? (
            <IconButton testid="iconButtonRight" disabled={isRotating}>
              <RotateRight
                testid="RotateRight"
                className={classes.downloadPhoto}
                onClick={this.onRotatePhoto(90.0)}
              />
            </IconButton>
          ) : null,
        ]}
        imageTitle={checkpointAnswerPhotosByMission[photoIndex].shortDescription}
        imageCaption={checkpointAnswerPhotosByMission[photoIndex].shortDescription}
        style={styles.lightbox}
      />
    );
  }

  renderDownloadPhotoButton = () => {
    const { checkpointAnswerPhotosByMission, missionId, submissionId, record } = this.props;

    return (
      <DownloadAndZipPhotosButton
        testid="downloadPhotosByVisitButton"
        photoFolders={preparePhotoFoldersWithoutPhotosPerSubmission(
          checkpointAnswerPhotosByMission,
          `${missionId} + ' - ' + ${submissionId}`,
        )}
        tooltip={`Download submission ${submissionId}`}
        campaignId={record.id}
        showIcon
        onTrackEvent={status => this.handleTrackSegmentEvent(status)}
        url={`/campaigns/${record.id}/photos/zip`}
        method={Method.POST}
      />
    );
  };

  renderTitle = () => {
    const {
      location,
      submissionId,
      permissions,
      checkpointAnswerPhotosByMission,
      locationDetails,
    } = this.props;
    const { isLoading, titleOverflowing } = this.state;

    const locationName = locationDetails ? locationDetails.name : location;
    const tooltipTitle = this.formatLocationTooltip(locationDetails || location);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          className={titleOverflowing ? 'titleContainer' : ''}
          testid="titleTooltip"
          title={tooltipTitle}
          style={{
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <span
            testid="mission"
            ref={this.photoTitleRef}
            className={titleOverflowing ? 'truncate' : ''}
          >
            {locationName}
          </span>
        </div>

        {permissions ? <ReviewersLink testid="reviewersLink" id={submissionId} /> : null}
        {isLoading ? <CircularProgress size={20} testid="loading" /> : null}
        {checkpointAnswerPhotosByMission ? this.renderDownloadPhotoButton() : null}
      </div>
    );
  };

  renderSegmentToggleIcon = (showAllSegments, segmentText) =>
    showAllSegments ? (
      <UnfoldLessOutlined
        style={{
          color: '#0071bc',
        }}
        testId="unfoldLessOutlined"
        onClick={this.hideAllSegment}
      />
    ) : (
      <Tooltip testId="segmentTooltip" title={segmentText}>
        <UnfoldMoreOutlined
          style={{
            color: '#0071bc',
          }}
          onClick={this.showAllSegments}
          testId="unfoldMoreOutlined"
        />
      </Tooltip>
    );

  renderSubheader = () => {
    const { cycle, segments, segmentPanelExpanded } = this.props;

    const { showSegmentToggle } = this.state;

    const { segmentText } = this.formatSegmentDescriptions(segments, cycle);

    return (
      <div className="container">
        <div
          ref={this.segmentTextRef}
          className="truncate"
          testId="hiddenSegmentText"
          style={{
            maxWidth: '95%',
            visibility: 'hidden',
            position: 'absolute',
            whiteSpace: 'nowrap',
          }}
        >
          {segmentText}
        </div>
        <div
          className={segmentPanelExpanded ? '' : 'truncate'}
          testId="segmentText"
          style={{
            maxWidth: '95%',
          }}
        >
          {segmentText}
        </div>
        {showSegmentToggle && (
          <div testId="renderSegmentToggleIcon" className="renderSegmentToggleButton">
            {this.renderSegmentToggleIcon(segmentPanelExpanded, segmentText)}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      classes,
      checkpointAnswerPhotosByMission,
      permissions,
      record,
      onHandleErrorMessage,
      width,
      onUpdateMissionSegmentCollapseToggle,
      missions,
      submissionId,
    } = this.props;
    const { isOpen } = this.state;
    /**
     * This logic is responsible for initializing missions that are not yet present in the global state (`missions`).
     * When a mission with a specific `submissionId` does not exist in the global state, this block will create an entry for it
     * and set its `segmentPanelExpanded` property to match the global value of `allSegmentsPanelExpanded`.
     *
     * This ensures that newly loaded or added missions get the correct default value, even if they were introduced after
     * the initial render. It also handles re-rendering cases, ensuring that the state is consistently updated whenever new
     * missions appear that are not yet tracked in the global state.
     */
    if (missions && !missions[submissionId]) {
      onUpdateMissionSegmentCollapseToggle(submissionId, missions.allSegmentsPanelExpanded);
    }
    return (
      <Grid
        style={{
          width: 'auto',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: 10,
          marginRight: 15,
        }}
        direction="row"
      >
        <CardHeader
          testid="location"
          title={this.renderTitle()}
          subheader={this.renderSubheader()}
          classes={{ title: classes.cardHeader }}
        />

        <div
          className={classes.root}
          ref={element => {
            this.galleryElement = element;
          }}
        >
          <GridList className={classes.gridList}>
            <PhotosPerSubmission
              testid="photosPerSubmission"
              checkpointAnswerPhotosByMission={checkpointAnswerPhotosByMission}
              permissions={permissions}
              campaignId={record.id}
              openLightbox={this.openLightbox}
              handleImageChange={this.handleImageChange}
              onHandleErrorMessage={onHandleErrorMessage}
              width={width}
              onForceReloadPhoto={this.onForceReloadPhoto}
            />
          </GridList>
          {isOpen && this.renderLightBox()}
        </div>
      </Grid>
    );
  }
}

UserCheckpointAnswerPhotoCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.string.isRequired,
  locationDetails: PropTypes.string,
  checkpointAnswerPhotosByMission: PropTypes.arrayOf(
    PropTypes.shape({
      mission: PropTypes.string,
      shortDescription: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
  ),
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  record: PropTypes.objectOf(PropTypes.any),
  submissionId: PropTypes.string.isRequired,
  missionId: PropTypes.string.isRequired,
  onUpdatePhotos: PropTypes.func.isRequired,
  permissions: PropTypes.bool.isRequired,
  cycle: PropTypes.string,
  onHandleErrorMessage: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  onTrackEvent: PropTypes.func.isRequired,
  missions: PropTypes.bool.isRequired,
  onUpdateMissionSegmentCollapseToggle: PropTypes.func.isRequired,
  segmentPanelExpanded: PropTypes.bool.isRequired,
  onSegmentPanelExpansion: PropTypes.func.isRequired,
};

UserCheckpointAnswerPhotoCard.defaultProps = {
  checkpointAnswerPhotosByMission: null,
  record: null,
  cycle: null,
  locationDetails: null,
};

export const UserCheckpointAnswerPhotoPresenter = withStyles(styles)(UserCheckpointAnswerPhotoCard);

const UserCheckpointAnswerPhotoCardWithPermissions = props => (
  <WithPermissions
    render={({ permissions }) => (
      <UserCheckpointAnswerPhotoCard {...props} permissions={permissions} />
    )}
  />
);

export default withStyles(styles)(UserCheckpointAnswerPhotoCardWithPermissions);
