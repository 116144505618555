/**
 * Retrieves the missions from the state.
 *
 * @param {Object} state - The global state object.
 * @returns {Object[]} - The array of missions from the state, or an empty array if not present.
 */
const getMissions = state => (state && state.missions ? state.missions : []);

/**
 * Retrieves the global state indicating whether all segments are collapsed.
 *
 * @param {Object} state - The global state object.
 * @returns {boolean} - `true` if all segments are collapsed, `false` otherwise.
 */
export const getAllSegmentsCollapsed = state =>
  state && state.missions && state.missions.allSegmentsPanelExpanded
    ? state.missions.allSegmentsPanelExpanded
    : false;

/**
 * Retrieves the collapse state of a specific mission segment.
 *
 * @param {Object} state - The global state object.
 * @param {string} submissionId - The ID of the mission.
 * @returns {boolean} - `true` if the mission segment is collapsed, `false` otherwise.
 */
export const getMissionSegmentCollapsed = (state, submissionId) => {
  const missionToggleSegment =
    state && state.missions && state.missions[submissionId]
      ? state.missions[submissionId].segmentPanelExpanded
      : false;
  return missionToggleSegment;
};

export default getMissions;
