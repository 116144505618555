import React, { useState, useEffect, useContext } from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import PropTypes from 'prop-types';
import MetaBaseGraph from './components/metabaseGraph/metabaseGraph';
import DrawerComponent from './components/drawer/drawer';
import { UserContext } from '../../../../../context/UserContext';
import { trackSegmentEvents } from '../../../components/segment/segmentEventTrack';
import TitleWithPermissionsContainer from './components/title/title.container';

const styles = {
  title: {
    position: 'absolute',
    width: '97vw',
  },
  dashboardGraphs: {
    position: 'relative',
    top: '10vh',
    width: '97vw',
  },
  root: {
    height: '100%',
  },
};

const Actions = () => <span />;

const Dashboard = props => {
  const [gallery, setGallery] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState('50vh');
  const [galleryIsLoaded, setGalleryIsLoaded] = useState(false);
  const [galleryIsNotEmpty, setGalleryIsNotEmpty] = useState(false);
  const [user, setUser] = useState(undefined);
  const [customerSubscriptions, setCustomerSubscriptions] = useState(undefined);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setUser(userContext.user);
    setCustomerSubscriptions(userContext.customerSubscriptions);
  }, [userContext, props.id, user, customerSubscriptions]);

  const onTrackEvent = (type, params) => {
    if (user) trackSegmentEvents(type, params, user, customerSubscriptions);
  };

  const onToggleViewGallery = height => {
    setGallery(true);
    setDrawerHeight(height);
  };

  const onGalleryIsLoaded = bool => {
    setGalleryIsLoaded(bool);
  };

  const onGalleryIsNotEmpty = bool => {
    setGalleryIsNotEmpty(bool);
  };

  const toggleDrawer = () => {
    setGallery(prevState => !prevState);
    setDrawerHeight('0vh');
  };

  return (
    <Show testid="title" {...props} actions={<Actions />} style={styles.root}>
      <SimpleShowLayout>
        <TitleWithPermissionsContainer
          testid="titleComponent"
          onToggleViewGallery={onToggleViewGallery}
          isGalleryLoaded={galleryIsLoaded}
          isGalleryNotEmpty={galleryIsNotEmpty}
          style={styles.title}
          onTrackEvent={onTrackEvent}
          openGallery={gallery}
        />
        <MetaBaseGraph
          {...props}
          testid="metabaseGraph"
          onGalleryIsLoaded={onGalleryIsLoaded}
          onGalleryIsNotEmpty={onGalleryIsNotEmpty}
          style={styles.dashboardGraphs}
        />
        <DrawerComponent
          {...props}
          testid="drawer"
          open={gallery}
          onToggleDrawer={toggleDrawer}
          height={drawerHeight}
          onGalleryIsLoaded={onGalleryIsLoaded}
          onGalleryIsNotEmpty={onGalleryIsNotEmpty}
        />
      </SimpleShowLayout>
    </Show>
  );
};

Dashboard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Dashboard;
