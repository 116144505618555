import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-virtualized';

import Photo from './photo';

export class PhotosPerSubmissionPresenter extends React.PureComponent {
  render() {
    const { checkpointAnswerPhotosByMission, width, onForceReloadPhoto } = this.props;
    return (
      <Grid
        testid="photosPerSubmission"
        width={width}
        height={200}
        columnWidth={150}
        overscanColumnCount={5}
        columnCount={checkpointAnswerPhotosByMission.length}
        rowHeight={180}
        rowCount={1}
        cellRenderer={({ style, key, columnIndex }) => {
          const checkpointAnswerPhoto = checkpointAnswerPhotosByMission[columnIndex];
          return (
            <div style={style} key={key}>
              <Photo
                onForceReloadPhoto={onForceReloadPhoto}
                checkpointAnswerPhoto={checkpointAnswerPhoto}
                index={columnIndex}
                {...this.props}
              />
            </div>
          );
        }}
      />
    );
  }
}

PhotosPerSubmissionPresenter.propTypes = {
  checkpointAnswerPhotosByMission: PropTypes.arrayOf(
    PropTypes.shape({
      mission: PropTypes.string,
      shortDescription: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
  ).isRequired,
  permissions: PropTypes.bool.isRequired,
  campaignId: PropTypes.string.isRequired,
  openLightbox: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  onHandleErrorMessage: PropTypes.func.isRequired,
  onForceReloadPhoto: PropTypes.func.isRequired,
};

export default PhotosPerSubmissionPresenter;
